import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import * as actionTypes from './actionTypes';
import appConfig from '../../appConfig.json';
import { showToaster } from './common';

const BASE_URL = appConfig.BASE_URL;

export const authStart = () => {
    return {
        type : actionTypes.AUTH_START
    };
};

export const authSuccess = (user) => {
    return {
        type : actionTypes.AUTH_SUCCESS,
        user :  user,
    };
};

export const authFail = (errorMsg) => {
    return {
        type : actionTypes.AUTH_FAIL,
        errorMsg : errorMsg
    }
}

export const signOut = () => {
    return {
        type : actionTypes.SIGN_OUT
    };
};

export const loginUser = (userData) => {
    return dispatch => {
        dispatch(authStart());
        
        axios
            .post(BASE_URL + "/api/authentication", userData)
            .then(res => {    
                localStorage.setItem("jwtToken", res.data.accessToken);
                localStorage.setItem("userDetailId", res.data.user.userDetailId);
                // Set token to Auth header
                setAuthToken(res.data.accessToken);
                dispatch(authSuccess(res.data.user));
                dispatch(showToaster('success','User Successfully login.'));
            }).catch(error => {
                // Something happened in setting up the request that triggered an Error                
                dispatch(authFail());                
                dispatch(showToaster('error',error.response.data.message))                
            });
    };
};

export const autoLoginUser = (userId) => {
    return dispatch => {
        dispatch(authStart());
        
        axios
            .get(BASE_URL + "/api/users/" + userId)
            .then(res => {    
                dispatch(authSuccess(res.data));
            }).catch(error => {
                // Something happened in setting up the request that triggered an Error
                dispatch(authFail());
                dispatch(showToaster('error',error.response.data.message))
            });
    };
};

export const logoutUser = () => dispatch => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove userDetailId from local storage
    localStorage.removeItem("userDetailId");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set Auth reducer to initial state
    dispatch(signOut());
};

